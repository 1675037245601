import React from 'react';
import './Header.css'
import logo from 'assets/logo/MarisaLogo.webp'
import { createClient } from "contentful"

class Header extends React.Component {

    constructor() {
        super();
        this.state = {
            header: null
        };
    }

    componentDidMount() {
        this.getHeader();
    }

    async getHeader() {
        const client = await createClient({
            space: '5ehmxbuqyhrp',
            accessToken: 'toi3mvFOzK9QwTIT8oZEEEHjYKAeHg-1qkYdIyynwfE'
        });

        const response = await client.getEntry('1rkTlrEblcLD0GkPmgtide');
        const image = response.fields.header.fields.file.url;

        this.setState({
            header: image,
        })
    }

    render() {
        var image = this.state.header;

        return <div>
            <div className='parallax' style={{backgroundImage: `url(${image})`}}><img className='logo img-fluid' src={logo} alt="" loading='lazy' /></div>
        </div>
    }
}

export default Header