import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PDF from "assets/resources/ALGEMENEVOORWAARDEN.pdf";
import "././Footer.css";

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            year: this.getCurrentYear()
        }
    }

    getCurrentYear() {
        return new Date().getFullYear().toString();
    };

    render() {
        const year = this.state.year;

        return (
            <div className="pt-3" id="footer">
                <Container className="footer text-center">
                    <Row xs={1}>
                        <Col className="termsAndConditions" >
                            <a className="footerText" target="_blank" rel="noreferrer" href={PDF} >Algemene voorwaarden</a>
                        </Col>
                        <Col>
                            <span className="footerText" id="year">&copy; {year} Nails by Marisa - Website door </span>
                            <a className="footerText" href="https://www.linkedin.com/in/tom-kwarten-a60b00175/" target="_blank" rel="noreferrer">Tom Kwarten</a>
                            <br />
                            <p className="footerText">KVK: 76283577</p>
                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }
}

export default Footer;