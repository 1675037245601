import "./SocialFeed.css";
import React from "react";
import { Container } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { createClient } from "contentful";
import Instaphoto from "assets/icons/instaPhoto.jpg";
import HoverImg from "assets/icons/instaHover.png";

class SocialFeed extends React.Component {

    constructor() {
        super();
        this.state = {
            images: []
        };
    }

    componentDidMount() {
        this.getImages();
    }

    async getImages() {
        const client = await createClient({
            space: '5ehmxbuqyhrp',
            accessToken: 'toi3mvFOzK9QwTIT8oZEEEHjYKAeHg-1qkYdIyynwfE'
        });

        const response = await client.getEntry('l3bA9DodMsovtMWSRVjnJ');
        const imageData = response.fields.instagramPhotos;

        var imagesList = [];
        imageData.forEach(image => {
            var imageObject = {
                original: image.fields.file.url,
                thumbnail: image.fields.file.url,
                originalAlt: "instagram photo",
                thumbnailAlt: "instagram photo",
            }

            imagesList.push(imageObject);
        });

        this.setState({
            images: imagesList,
        })
    }

    render() {
        return (
            <Container className="socialFeed text-center pb-4">
                <div className="m-4">
                    <h2 className="mb-3">Bekijk hier de resultaten</h2>
                    <a className="instaContainer" href="https://www.instagram.com/nails.bymarisa/" target="_blank" rel="noreferrer">
                        <img className="profileImg" src={Instaphoto} target="_blank" rel="noreferrer" alt="profileImg" loading="lazy"></img>
                        <img className="hoverImg" src={HoverImg} target="_blank" rel="noreferrer" alt="hoverImg" loading="lazy"></img>
                    </a>
                    <a className="instaName ml-2" href="https://www.instagram.com/nails.bymarisa/"><b>  nails.bymarisa</b></a>
                </div>
                {this.state.images ? <ImageGallery items={this.state.images} lazyLoad={true} showPlayButton={false} autoPlay={true}/> : null}
            </Container>
        )
    }
}

export default SocialFeed;