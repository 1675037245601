import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Maps from '../maps/Maps';
import EmailIcon from 'assets/icons/email.png';
import PhoneIcon from 'assets/icons/phone.png';
import MarkerIcon from 'assets/icons/marker.png';
import "./Contact.css";

class Contact extends React.Component {
    async acceptWindow() {
        if (window.confirm('Google maps route openen?')) {
            window.open('https://goo.gl/maps/XLc6Suc6djq5n6xYA')
        }
    }

    render() {
        return (
            <Row className='contactPage'>
                <Col xs={12} md={6}>
                    <Maps></Maps>
                </Col>
                <Col xs={12} md={6}>
                    <div className='inner'>
                        <h1 >Contact met mij opnemen</h1>
                    <h4>U kunt ons bereiken via de volgende manieren</h4>

                        <Row className="contact justify-content-center">
                            <Col>
                                <h3 className="card-title">Contact</h3>
                                <Row className='m-2 my-4'>
                                    <a className="ref d-flex" href="tel:+31 6 43616937">
                                        <Col md={3} lg={4}>
                                            <img className="contactIcon" alt="nummer" src={PhoneIcon} loading='lazy' />
                                        </Col>
                                        <Col md={6}>
                                            <p>+(06) 436 169 37  </p>
                                        </Col>
                                    </a>
                                </Row>
                                <Row className='m-2'>
                                    <a className="ref d-flex" href="mailto:nails.bymarisa@hotmail.com">
                                        <Col md={3} lg={4}>
                                            <img className="contactIcon float-right" alt="email" src={EmailIcon} loading='lazy' />
                                        </Col>
                                        <Col md={8}>
                                            <p className="email">nails.bymarisa@hotmail.com</p>
                                        </Col>
                                    </a>
                                </Row>
                            </Col>
                            <Col>
                                <h3 className="card-title">Adres</h3>
                                <Row className='m-2 my-4'>
                                    <a className="ref d-flex" onClick={this.acceptWindow}>
                                        <Col md={3} lg={4}>
                                            <img className="contactIcon" alt="adres" src={MarkerIcon} loading='lazy' />
                                        </Col>
                                        <Col >
                                            <p>Veeringveld 5, 1541 SG Koog aan de Zaan</p>
                                        </Col>
                                    </a>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default Contact;