import AboutMe from "../about-me/AboutMe";
import Header from "components/common/header/Header";
import React from "react";
import Maps from "components/content/maps/Maps";
import SocialFeed from "../social-feed/SocialFeed";

class Home extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <AboutMe />
                <Maps />
                <SocialFeed />
            </div>
        );
    }
}

export default Home;