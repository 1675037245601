import './TopBar.css'
import logo from 'assets/logo/MarisaLogo.webp'
import React from "react";
import { FaFacebookF, FaInstagram, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'
import { Container, Navbar, NavDropdown, Nav, Row, Col} from 'react-bootstrap';	

class TopBar extends React.Component {

    render() {
        return <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={logo}
                        width="150"
                        height="90"
                        className="d-inline-block align-top"
                        loading='lazy'
                    />{' '}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className="h5" href="/">Home</Nav.Link>
                        <Nav.Link className="h5" href="/prijslijst">Prijslijst</Nav.Link>
                        {/* <Nav.Link className="h5" href="/shop">Shop</Nav.Link> */}
                        {/* <NavDropdown className="h5" title="Extra informatie">
                            <NavDropdown.Item className="h6" href="/informatie/BIAB">BIAB</NavDropdown.Item>
                            <NavDropdown.Item className="h6" href="/informatie/wasparfum">Wasparfum</NavDropdown.Item>
                            <NavDropdown.Item className="h6" href="/informatie/opleiding">Opleiding</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link className="h5" href="/contact">Contact</Nav.Link>
                    </Nav>
                    <Nav>
                        <Row className="justify-content-center m-2" xs="auto">
                            <Col><a className="icon" target="_blank" rel="noreferrer" href="https://www.instagram.com/nails.bymarisa/"><FaInstagram size="1.3em" /></a></Col>
                            <Col><a className="icon" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=31683165898"><FaWhatsapp size="1.3em" /></a></Col>
                            <Col><a className="icon" href="tel:+31 6 43616937"><FaPhoneAlt size="1.3em" /></a></Col>
                            <Col><a className="icon" target="_blank" rel="noreferrer" href="https://www.facebook.com/%F0%9D%93%9D%F0%9D%93%AA%F0%9D%93%B2%F0%9D%93%B5%F0%9D%93%BC-%F0%9D%93%AB%F0%9D%94%82-%F0%9D%93%9C%F0%9D%93%AA%F0%9D%93%BB%F0%9D%93%B2%F0%9D%93%BC%F0%9D%93%AA-114281196658210"><FaFacebookF size="1.3em" /></a></Col>
                        </Row>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>;
    }
}

export default TopBar