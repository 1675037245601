import React from 'react';
import './AboutMe.css'
import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap';
import { createClient } from 'contentful';
import image2 from 'assets/content/PortretFoto2.jpg';

class AboutMe extends React.Component {
    constructor() {
        super();
        this.state = {
            image: null
        };
    }

    componentDidMount() {
        this.getImage();
    }

    async getImage() {
        const client = await createClient({
            space: '5ehmxbuqyhrp',
            accessToken: 'toi3mvFOzK9QwTIT8oZEEEHjYKAeHg-1qkYdIyynwfE'
        });

        const response = await client.getEntry('3dOqd6P6SFPD97sISB8YvW');
        const image = response.fields.portret.fields.file.url;

        this.setState({
            image: image,
        })
    }

    render() {
        return <Container className='aboutContainer pb-5 mt-2'>
            <Row xs={1} md={2}>
                <Col className='text-center mt-4'>
                    <h2>Over mij</h2>
                    <p>Mijn naam is Marisa en sinds 2019 trotse eigenaar van Nails by Marisa.
                        Voor mijn studie liep ik al enige tijd met het idee een onderneming te starten en wat is mooier om van je hobby je beroep te maken.
                        Mijn interesse ging al jaren uit naar persoonlijke verzorging met name handen en nagels, naast mijn HBO studie ben ik een opleiding
                        gaan volgen en sinds 2019 gediplomeerd Manicure en Acrylic Technician.
                    </p>

                    <p>
                        Kwaliteit en Hygiëne staan bij mij hoog in het vaandel, naast het aanbrengen van acryl en gelnagels voer ik ook de Russian Manicure uit.
                        Met deze techniek worden je nagelriemen ter voorbereiding van de behandeling zacht en blijven ze langer mooi en zijn je nagels een lust voor het oog.
                    </p>

                    <p>
                        Neem rustig een kijkje in mijn fotogalerie of laat je handen en nagels lekker verwennen en neem met mij contact op voor het maken van een afspraak!
                    </p>
                </Col>
                <Col className='mt-5'>
                    <img className='portretImage' src={this.state.image} alt='portret' loading='lazy' />
                </Col>
            </Row>
        </Container>
    }
}

export default AboutMe