import React from "react"

class Maps extends React.Component {
    render() {
        return (
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d859.8520131424962!2d4.810202768822094!3d52.44239383294452!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbbdb0cc1e9f7cc23!2sNails%20by%20Marisa!5e0!3m2!1snl!2snl!4v1597229474590!5m2!1snl!2snl"
                title="Nails by Marisa locatie"
                width="100%"
                height="100%"
                aria-hidden="false"
                style={{minHeight: "400px"}}>
            </iframe>
        )
    }
}

export default Maps;