import React from "react"
import { Container, Row, Table } from "react-bootstrap";
import { createClient } from "contentful";
import "./PriceList.css";

class PriceList extends React.Component {
    constructor() {
        super();
        this.state = {
            prices: []
        };
    }

    componentDidMount() {
        this.getPrices();
    }

    async getPrices() {
        const client = await createClient({
            space: '5ehmxbuqyhrp',
            accessToken: 'toi3mvFOzK9QwTIT8oZEEEHjYKAeHg-1qkYdIyynwfE'
        });

        const response = await client.getEntry('5BO0YafaBQz7fVfP752haX');
        const priceData = response.fields.prijslijst.BIAB;

        var priceArray = [];
        priceData.forEach(price => {
            priceArray.push(price);
        });

        this.setState({
            prices: priceArray,
        })
    }

    render() {
        return (
            <div className="priceList pb-4">
                <Container className='priceContent pt-4'>
                    <Row className="justify-content-center">
                        <div className='priceTitle text-center col-auto'>
                            <h2>Prijslijst</h2>
                            <h4>Alle behandelingen</h4>
                        </div>
                    </Row>
                    <div className="card mt-3">
                        <div className="card-body">
                        <Table striped hover>
                                <thead>
                                    <tr>
                                        <th scope="col">Behandeling</th>
                                        <th scope="col">Prijs</th>
                                        <th scope="col">Tijd</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.prices ? this.state.prices.map((price, index) => {
                                        return (
                                                <tr key={price.name}>
                                                    <td>{price.name}</td>
                                                    <td>{price.price}</td>
                                                    <td>{price.time}</td>
                                                </tr>
                                        )
                                    }
                                    ) : null}
                                </tbody>
                            </Table>

                        </div>
                    </div>
                </Container>
            </div>

        )
    }
}

export default PriceList;