import TopBar from "./components/common/top-bar/TopBar";
import Footer from "./components/common/footer/Footer";
import PriceList from "./components/content/price-list/PriceList";
import Home from "./components/content/home/Home";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import React from "react";
import Contact from "components/content/contact/Contact";

class App extends React.Component {
    render() {
        return (
            <Router>
                <TopBar />
                <Routes>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='prijslijst' element={<PriceList />}></Route>
                    <Route path='contact' element={<Contact />}></Route>
                </Routes>
                <Footer />
            </Router>
        );
    }
}

export default App;